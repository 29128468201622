<template>
  <div :key="subjectAttachemts.length">
    <div v-if="subjectAttachemts.length <= 0">
      Neboli pridané žiadne prílohy
    </div>
    <div v-else>
      <div
        v-for="(attachment, idx) in subjectAttachemts"
        :key="idx"
        class="ma-0 pa-0"
      >
        <v-list-item
          two-line
          class="ma-0 pa-0"
          dense
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon
                left
                class="info2--text ma-0 pa-0"
              >
                mdi-clippy
              </v-icon>
              <span class="info2--text ma-0 pa-0">
                {{ attachment.fileName }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-btn
                text
                class="primary ma-1"
                x-small
                @click="downloadAttachment(attachment)"
              >
                download
              </v-btn>
              <v-btn
                text
                class="primary ma-1"
                x-small
                @click="openDeleteAttachmentDialog(attachment)"
              >
                delete
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>

    <v-btn
      style="top: 10px;"
      color="secondary"
      elevation="0"
      fab
      dark
      x-small
      absolute
      top
      right
      @click="addAttachmentDialogOpen()"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <basic-dialog
      v-model="addAttachmentDialog"
      title="$l.resolve.uploadAttachment"
      @close-dialog="closeAddAttachmentDialog()"
    >
      <AttachmentFileUploader
        :attach-to-identifiers="{ subject: partData.subjectname, subjectId: partData.id }"
        class="ma-3"
        @closeAttachmentDialog="closeAddAttachmentDialog()"
      />
    </basic-dialog>

    <basic-dialog
      v-model="confirmDeleteDialog"
      title="Potvrdiť zmazanie súboru"
      :subtitle="attachmentToDelete ? attachmentToDelete.fileName : ''"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="confirmDeleteDialog = false"
      @save-dialog="doDeleteFileAttachment"
    />
  </div>
</template>

<script>
import AttachmentFileUploader from './AttachmentFileUploader.vue'

const readFile = (inputFile) => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort()
      reject(new Error('Problem parsing input file.'))
    }
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsDataURL(inputFile)
  })
}

export default {
  name: 'SubjectCardPart4',
  components: {
    AttachmentFileUploader
  },
  props: {
    partData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      addAttachmentDialog: false,
      selectedFile: null,
      subjectAttachemts: [],
      confirmDeleteDialog: false,
      attachmentToDelete: null
    }
  },
  mounted () {
    this.reloadAttachments()
  },
  methods: {
    addAttachmentDialogOpen () {
      this.addAttachmentDialog = true
    },
    reloadAttachments () {
      this.$xapi.get('endpointsFile/subjectfile/subject/' + this.partData.id)
        .then(r => {
          this.subjectAttachemts = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.currentCase = this.$store.getters.getCurrentCase
          this.callResult.finished = true
        })
    },
    downloadAttachment (attachment) {
      this.$xapi.post('endpointsFile/subjectfile/download', attachment, { responseType: 'blob' })
        .then(r => {
          readFile(r.data)
            .then(fd => {
              const downloadLink = document.createElement('a')
              document.body.appendChild(downloadLink)
              downloadLink.href = fd
              downloadLink.target = '_self'
              downloadLink.download = '_' + attachment.fileName
              downloadLink.click()
            })
        })
    },
    closeAddAttachmentDialog () {
      this.addAttachmentDialog = false
      this.$emit('subjectListParamsChanged', this.partData.id)
      // this.reloadAttachments()
    },
    openDeleteAttachmentDialog (attachment) {
      this.attachmentToDelete = attachment
      this.confirmDeleteDialog = true
    },
    doDeleteFileAttachment () {
      this.$xapi.post('endpointsFile/subjectfile/delete', this.attachmentToDelete)
        .then(r => {
          this.attachmentToDelete = null
          this.confirmDeleteDialog = false
          this.$emit('subjectListParamsChanged', this.partData.id)
          // this.reloadAttachments()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
