<template>
  <div>
    <v-data-table
      dense
      :mobile-breakpoint="0"
      :items="items"
      :headers="headers"
      :options.sync="opts"
      :sort-by="'created'"
      :footer-props="{ itemsPerPageOptions: [10] }"
    >
      <template #[`item.created`]="{ item }">
        <span style="white-space: nowrap; font-size: 12px; font-weight: 500">{{ $utils.formatDate(new Date(item.created + ' UTC').toISOString()) }}</span>
      </template>
      <template #[`item.checkStatus`]="{ item }">
        <span
          v-if="item.checkStatus === 'NEW_OK'"
          style="white-space: nowrap"
        >
          <v-icon color="success">mdi-check-bold</v-icon>
        </span>
        <span
          v-if="item.checkStatus === 'NEW_MATCH'"
          style="white-space: nowrap"
        >
          <v-icon color="warning">mdi-alert-box-outline</v-icon>
        </span>
        <span
          v-if="item.checkStatus === 'NEW_UNSEARCHABLE'"
          style="white-space: nowrap"
        >
          <v-icon color="warning">mdi-close-thick</v-icon>
        </span>
        <span
          v-if="item.checkStatus === 'NEW_FAILED'"
          style="white-space: nowrap"
        >
          <v-icon color="info">mdi-close-thick</v-icon>
        </span>
        <span
          v-if="item.checkStatus === 'CONFIRMED_OK'"
          style="white-space: success"
        >
          <v-icon color="success">mdi-close-thick</v-icon>
        </span>
        <span
          v-if="item.checkStatus === 'CONFIRMED_MATCH'"
          style="white-space: nowrap"
        >
          <v-icon color="error">mdi-close-thick</v-icon>
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'SubjectCardPart3',
  props: {
    partData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opts: null
    }
  },
  computed: {
    items () {
      const items = []
      const checksMap = new Map(Object.entries(JSON.parse(this.partData.transientData).checks))
      checksMap.forEach((value, key) => {
        items.push(value)
      })
      return items
    },
    headers () {
      return [
        { text: this.$t('Created'), value: 'created', sortable: true, sort: (a, b) => this.getCreatedSort(a, b) },
        { text: this.$t('Status'), value: 'checkStatus', sortable: true },
        { text: this.$t('Spent'), value: 'subjectSpentAmount', sortable: true },
        { text: this.$t('Aff'), value: 'apiUserAffiliate', sortable: true },
        { text: this.$t('User'), value: 'apiUserName', sortable: true },
        { text: this.$t('ID'), value: 'subjectId', sortable: true },
        { text: this.$t('CID'), value: 'cardId', sortable: true },
        { text: this.$t('Cntr'), value: 'country', sortable: true },
        { text: this.$t('CntrRisk'), value: 'countryRiskLevel', sortable: true },
        { text: this.$t('PEPM'), value: 'pepMatches', sortable: true },
        // { text: this.$t('PEPUn'), value: 'pepUnsearchable', sortable: false },
        { text: this.$t('SancM'), value: 'sanctionMatches', sortable: true },
        // { text: this.$t('SancUn'), value: 'sanctionUnsearchable', sortable: false },
        { text: this.$t('CheckOK'), value: 'checkFinished', sortable: true }
      ]
    }
  },
  methods: {
    getCreatedSort (a, b) {
      const desc = !this.opts.sortDesc[0]
      return (a.id > b.id) ? (desc ? 1 : -1) : (desc ? -1 : 1)
    }
  }
}
</script>
