<template>
  <div>
    <div
      v-for="(item, i) in warnings"
      :key="i"
    >
      <v-row
        class="ma-0 pa-0 pb-1"
        :class="item.warning ? 'error--text' : 'success--text'"
      >
        {{ $t(`$l.api.subjects.warnings.${item.name}`) }}
        <span
          v-if="item.threshold > 0"
          class="ml-1"
        >{{ `(${item.total}>${item.threshold})` }}</span>
        <v-col class="ma-0 pa-0 text-end">
          <v-btn
            v-if="['PEP', 'SANCTION'].includes(item.name)"
            text
            x-small
            outlined
            color="info2"
            @click="warningActionEvaluateDialog(true, item)"
          >
            {{ $t('$l.api.subjects.warningActionEvaluate') }}
          </v-btn>
          <v-btn
            v-if="['CARDID'].includes(item.name)"
            text
            x-small
            outlined
            color="info2"
            @click="warningActionCardIdsDialog(true)"
          >
            {{ $t('$l.app.display') }}
          </v-btn>
          <v-btn
            v-if="['IDNR', 'CARDIDNR', 'TOTALSUM', 'COUNTRYNR'].includes(item.name)"
            text
            x-small
            outlined
            color="info2"
            @click="warningActionEditLimitDialog(true, item)"
          >
            {{ $t('$l.api.subjects.warningActionEditLimit') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-btn
      style="top: 10px;"
      color="secondary"
      elevation="0"
      fab
      dark
      x-small
      absolute
      top
      right
      @click="downloadReportDialogOpen()"
    >
      <v-icon>mdi-file</v-icon>
    </v-btn>

    <basic-dialog
      v-model="downloadReportDialog"
      :title="'$l.api.subjects.downloadSubjectReport'"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      :disable="callResult.finished"
      @close-dialog="downloadReportDialog = false"
      @save-dialog="doDownloadReport()"
    >
      <v-menu
        v-model="menuRepDateFrom"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="repDateFrom"
            class="ma-0 pa-3"
            :label="$t('$l.apicheck.dateFrom')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="repDateFrom"
          @input="menuRepDateFrom = false"
        />
      </v-menu>
      <v-menu
        v-model="menuRepDateTo"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="repDateTo"
            class="ma-0 pa-3"
            :label="$t('$l.apicheck.dateTo')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="repDateTo"
          @input="menuRepDateTo = false"
        />
      </v-menu>
    </basic-dialog>

    <basic-dialog
      v-if="warningActionEvaluateDialogItem"
      v-model="warningActionEvaluateDialogOpen"
      :title="$t(`$l.api.subjects.warnings.${warningActionEvaluateDialogItem.name}`)"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      :disable="callResult.finished"
      @close-dialog="warningActionEvaluateDialog(false)"
      @save-dialog="warningActionEvaluateDialogConfirm"
    >
      <div class="px-4">
        <v-btn
          text
          class="success mr-3"
          small
          @click="warningActionEvaluateDialogItemSwitch(false)"
        >
          {{ $i18n.t('$l.api.notMatch') }}
          <v-icon
            v-if="warningActionEvaluateDialogItem.confirmNotPepOrSanctionPerson"
            right
            dark
          >
            mdi-check
          </v-icon>
        </v-btn>
        <v-btn
          text
          class="error"
          small
          @click="warningActionEvaluateDialogItemSwitch(true)"
        >
          {{ $i18n.t('$l.api.confirmMatch') }}
          <v-icon
            v-if="warningActionEvaluateDialogItem.confirmPepOrSanctionPerson"
            right
            dark
          >
            mdi-check
          </v-icon>
        </v-btn>
        <v-textarea
          ref="refAuditTrailNote1"
          v-model="auditTrailNote"
          class="mt-3"
          outlined
          filled
          rows="4"
          background-color="greyf6"
          color="success darken-1"
          :placeholder="'$l.api.subjects.warningChangeNote' | translate"
          prepend-inner-icon="mdi-comment-text"
          :rules="[v => v ? (v.length >= 10 ? true : $t('$l.app.min10charsRequired')) : '']"
          maxlength="256"
          counter
          :error-messages="($v.auditTrailNote.$dirty && !$v.auditTrailNote.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.auditTrailNote.$touch()"
          @blur="$v.auditTrailNote.$touch()"
        />
        <div
          v-if="callResult.error"
          class="error--text"
        >
          {{ callResult.error }}
        </div>
      </div>
    </basic-dialog>

    <basic-dialog
      v-model="warningActionCardIdsDialogOpen"
      title="$l.api.subjects.warnings.CARDID"
      @close-dialog="warningActionCardIdsDialog(false)"
    >
      <div
        v-for="(id, idx) in JSON.parse(partData.data).cardIds"
        :key="idx"
        class="px-4"
      >
        <span class="font-weight-bold mr-2">{{ idx }}</span>
        <span
          v-if="id == 1"
          class="error--text"
        >{{ $t('$l.api.lostIdCardWarning') }}</span>
        <span v-else><v-icon color="success">mdi-check-bold</v-icon></span>
      </div>
    </basic-dialog>

    <basic-dialog
      v-if="warningActionEditLimitDialogItem"
      v-model="warningActionEditLimitDialogOpen"
      :title="$t(`$l.api.subjects.warnings.${warningActionEditLimitDialogItem.name}`)"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      :disable="callResult.finished"
      @close-dialog="warningActionEditLimitDialog(false)"
      @save-dialog="warningActionEditLimitDialogConfirm"
    >
      <div class="px-4">
        <div class="font-weight-bold mb-2">
          {{ $t('$l.api.subjects.warningActionEditLimit') + ": " + warningActionEditLimitDialogItemThreshold }}
        </div>
        <div class="">
          {{ $t('$l.api.subjects.warningLimitEnterNew') }}
        </div>
        <v-text-field
          v-model="warningActionEditLimitDialogItem.threshold"
          :rules="[rules.required, rules.counter, rules.minval]"
          outlined
          dense
          counter
          maxlength="6"
          type="number"
        />
        <v-textarea
          ref="refAuditTrailNote2"
          v-model="auditTrailNote"
          outlined
          filled
          rows="4"
          background-color="greyf6"
          color="success darken-1"
          :placeholder="'$l.api.subjects.warningChangeNote' | translate"
          prepend-inner-icon="mdi-comment-text"
          :rules="[v => v ? (v.length >= 10 ? true : $t('$l.app.min10charsRequired')) : '']"
          maxlength="256"
          counter
          :error-messages="($v.auditTrailNote.$dirty && !$v.auditTrailNote.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.auditTrailNote.$touch()"
          @blur="$v.auditTrailNote.$touch()"
        />
      </div>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SubjectCardPart1',
  props: {
    partData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      warningActionEvaluateDialogOpen: false,
      warningActionEvaluateDialogItem: null,
      warningActionCardIdsDialogOpen: false,
      warningActionEditLimitDialogOpen: false,
      warningActionEditLimitDialogItem: null,
      warningActionEditLimitDialogItemThreshold: 0,
      auditTrailNote: null,
      rules: {
        required: value => !!value || 'Value is Required',
        counter: value => value.length <= 6 || 'Max value is 999999',
        minval: value => value > 0 || 'Min value is 1',
        minlen: value => value > 0 ? true : this.$t('$l.app.min10charsRequired')
      },
      downloadReportDialog: false,
      menuRepDateFrom: false,
      repDateFrom: null,
      menuRepDateTo: false,
      repDateTo: null
    }
  },
  computed: {
    warnings () {
      const warnings = []
      const warningsMap = new Map(Object.entries(JSON.parse(this.partData.data).subjectWarnings))
      warningsMap.forEach((value, key) => {
        warnings.push(value)
      })
      return warnings
    }
  },
  validations: {
    auditTrailNote: { required }
  },
  methods: {
    warningActionEvaluateDialog (val, item) {
      this.auditTrailNote = null
      this.$v.$reset()
      if (val) {
        this.warningActionEvaluateDialogItem = item
      } else {
        this.warningActionEvaluateDialogItem = null
      }
      this.warningActionEvaluateDialogOpen = val
    },
    warningActionEvaluateDialogConfirm () {
      this.$v.$touch()
      if (!this.$v.$invalid && this.$refs.refAuditTrailNote1.validate()) {
        const updateRequest = {}
        updateRequest.subjectWarning = this.warningActionEvaluateDialogItem
        this.processUpdate(updateRequest)
      }
    },
    warningActionEvaluateDialogItemSwitch (confirm) {
      if (confirm) {
        this.warningActionEvaluateDialogItem.confirmPepOrSanctionPerson = !this.warningActionEvaluateDialogItem.confirmPepOrSanctionPerson
        if (this.warningActionEvaluateDialogItem.confirmPepOrSanctionPerson) {
          this.warningActionEvaluateDialogItem.confirmNotPepOrSanctionPerson = false
        }
      } else {
        this.warningActionEvaluateDialogItem.confirmNotPepOrSanctionPerson = !this.warningActionEvaluateDialogItem.confirmNotPepOrSanctionPerson
        if (this.warningActionEvaluateDialogItem.confirmNotPepOrSanctionPerson) {
          this.warningActionEvaluateDialogItem.confirmPepOrSanctionPerson = false
        }
      }
    },
    warningActionCardIdsDialog (val) {
      this.warningActionCardIdsDialogOpen = val
    },
    warningActionEditLimitDialog (val, item) {
      this.auditTrailNote = null
      this.$v.$reset()
      if (val) {
        this.warningActionEditLimitDialogItem = item
        this.warningActionEditLimitDialogItemThreshold = item.threshold
      } else {
        this.warningActionEditLimitDialogItem = null
        this.warningActionEditLimitDialogItemThreshold = 0
      }
      this.warningActionEditLimitDialogOpen = val
    },
    warningActionEditLimitDialogConfirm () {
      if (this.warningActionEditLimitDialogItem.threshold > 0 && this.warningActionEditLimitDialogItem.threshold < 1000000) {
        this.$v.$touch()
        if (!this.$v.$invalid && this.$refs.refAuditTrailNote2.validate()) {
          const updateRequest = {}
          updateRequest.subjectWarning = this.warningActionEditLimitDialogItem
          this.processUpdate(updateRequest)
        }
      }
    },
    processUpdate (updateRequest) {
      this.callResult.error = null
      this.callResult.finished = false
      updateRequest.subjectId = this.partData.id
      updateRequest.auditTrailNote = this.auditTrailNote
      this.$xapi.post('apiSubject/updateSubjectWarnings', updateRequest)
        .then(r => {
          this.$emit('subjectListParamsChanged', this.partData.id)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    downloadReportDialogOpen () {
      this.downloadReportDialog = !this.downloadReportDialog
    },
    doDownloadReport () {
      let content = ''
      const data = JSON.parse(this.partData.data)
      const subjectDbId = this.partData.id

      content += `<body style="width: 800px; border: 1px solid black; padding: 8px; word-break: break-word;">`

      if (this.repDateFrom == null && this.repDateTo == null) {
        content += `<h2>Report AML preverení za celé obodobie</h2>`
      } else {
        content += `<h2>Report AML preverení za obdobie`
        if (this.repDateFrom != null) {
          content += ` od ${this.repDateFrom}`
        }
        if (this.repDateTo != null) {
          content += ` do ${this.repDateTo}`
        }
        content += `</h2>`
      }

      content += `<h3 style="margin: 6px 0px;">Id prevádzkovateľa: ${this.partData.apiHeadQuarter}</h3>`
      content += `<h3 style="margin: 6px 0px;">Preverenie subjektu: ${data.subjectName}</h3>`
      content += `<h3 style="margin: 6px 0px;">Dátum narodenia: ${data.subjectBirthDate}</h3>`

      content += `<div>Dostupné rodné čísla: ${data.ids}</div>`
      content += `<div>Dostupné doklady: `
      Object.keys(data.cardIds).forEach((key) => {
        if (data.cardIds[key] === 1) {
          content += `<span style="color: red">${key}</span>,`
        } else {
          content += `${key},`
        }
      })
      content += `</div>`
      content += `<div>Krajina/krajiny: ${data.countries}</div>`
      content += `<div>Posledné známe rodné číslo: ${this.partData.lastKnownId}</div>`
      content += `<div>Posledné známe id dokladu: ${this.partData.lastKnownCardId}</div>`

      content += `<h3 style="margin: 6px 0px 0px 0px;">Varovné signály:</h3>`

      Object.keys(data.subjectWarnings).forEach((key) => {
        const item = data.subjectWarnings[key]
        if (['PEP', 'SANCTION'].includes(item.name)) {
          content += '<div>' + this.$t(`$l.api.subjects.warnings.${item.name}`) + ': '
          if (item.warning === true) {
            content += `<span style="color: red;">ÁNO</span>`
          } else {
            content += `<span style="color: green;">NIE</span></div>`
          }
        }
      })

      content += `<div>Upozornenie na osobu zo sankčnej krajiny: `
      if (this.partData.countryWarning === true) {
        content += `<span style="color: red;">ÁNO</span>`
      } else {
        content += `<span style="color: green;">NIE</span>`
      }
      content += `</div>`

      content += `<div>Upozornenie na kradnutý alebo odcudzený doklad: `
      if (this.partData.cardIdWarning === true) {
        content += `<span style="color: red;">ÁNO</span>`
      } else {
        content += `<span style="color: green;">NIE</span>`
      }
      content += `</div>`

      content += `<h3 style="margin: 6px 0px 0px 0px;">Vyhodnotenie:</h3>`
      content += `<div>Subjekt je PEP: `
      if (this.partData.isPep === 'YES') {
        content += `<span style="color: red;">ÁNO</span>`
      } else {
        content += `<span">${this.partData.isPep}</span>`
      }
      content += `</div>`
      content += `<div>Subjekt je sankčná osoba: `
      if (this.partData.isSanctioned === 'YES') {
        content += `<span style="color: red;">ÁNO</span>`
      } else {
        content += `<span">${this.partData.isSanctioned}</span>`
      }
      content += `</div>`

      let nrChecks = 0
      let amount = 0

      Object.keys(data.checks).forEach((key) => {
        const ch = data.checks[key]
        const a = new Date(this.repDateFrom).getTime()
        const b = new Date(ch.created).getTime()
        const c = this.repDateTo === null ? new Date().getTime() : new Date(this.repDateTo).getTime() + 24000 * 3600

        if ((a < b) && (b < c)) {
          nrChecks++
          amount += ch.subjectSpentAmount
        }
      })

      content += `<h3 style="margin: 6px 0px 0px 0px;">Počet preverení / počet návštev subjektu: ${nrChecks}</h3>`

      content += `<h3 style="margin: 6px 0px 0px 0px;">Celková známa utratená suma: ${amount} Eur</h3>`

      content += `<h3 style="margin: 6px 0px 0px 0px;">Počet príloh: ${this.partData.nrAttachments}</h3>`

      content += `<h3 style="margin: 6px 0px 0px 0px;">Audit záznamy a poznámky:</h3>`
      data.auditTrails.forEach(at => {
        const a = new Date(this.repDateFrom).getTime()
        const b = new Date(at.created).getTime()
        const c = this.repDateTo === null ? new Date().getTime() : new Date(this.repDateTo).getTime() + 24000 * 3600

        if ((a < b) && (b < c)) {
          content += `<div style="margin-bottom: 0px;"><b>${this.$utils.formatDate(at.created)}</b></div>`
          content += `<div style="margin-bottom: 6px;">${JSON.stringify(at)}</div>`
        }
      })

      content += `</body>`

      const linkSource = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      downloadLink.href = linkSource
      downloadLink.target = '_self'
      downloadLink.download = 'report_subject_' + subjectDbId + '.html'
      downloadLink.click()
      this.downloadReportDialog = false
      this.repDateFrom = null
      this.repDateTo = null
    }
  }
}
</script>
