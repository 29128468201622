<template>
  <div>
    <app-api-call
      class="ma-0 pa-0 mb-3"
      :call-result="callResult"
    />

    <v-row dense>
      <v-col cols="3">
        <v-card
          outlined
          class="ma-0 pa-1"
        >
          <v-card-subtitle
            class="ma-0 pa-0 body-1 font-weight-medium info2--text"
            style="height: 46px;"
          >
            {{ $i18n.t('$l.api.subjects.cardPartWarnings') }}
          </v-card-subtitle>
          <SubjectCardPart1
            v-if="callResult.finished && subjectCard !== null"
            :part-data="subjectCard"
            @subjectListParamsChanged="propagateSubjectListParamsChanged"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          outlined
          class="ma-0 pa-1"
        >
          <v-card-subtitle
            class="ma-0 pa-0 body-1 font-weight-medium info2--text"
            style="height: 36px;"
          >
            {{ $i18n.t('$l.api.subjects.cardPartNotes') }}
          </v-card-subtitle>
          <SubjectCardPart2
            v-if="callResult.finished && subjectCard !== null"
            :part-data="subjectCard"
            @subjectListParamsChanged="propagateSubjectListParamsChanged"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          outlined
          class="ma-0 pa-1"
        >
          <v-card-subtitle
            class="ma-0 pa-0 body-1 font-weight-medium info2--text"
            style="height: 30px;"
          >
            {{ $i18n.t('$l.api.subjects.cardPartChecks') }}
          </v-card-subtitle>
          <SubjectCardPart3
            v-if="callResult.finished && subjectCard !== null"
            :part-data="subjectCard"
          />
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card
          outlined
          class="ma-0 pa-1"
        >
          <v-card-subtitle
            class="ma-0 pa-0 body-1 font-weight-medium info2--text"
            style="height: 30px;"
          >
            {{ $i18n.t('$l.api.subjects.cardPartAttachments') }}
          </v-card-subtitle>
          <SubjectCardPart4
            v-if="callResult.finished && subjectCard !== null"
            :part-data="subjectCard"
            @subjectListParamsChanged="propagateSubjectListParamsChanged"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubjectCardPart1 from '@/components/api/apiSubject/SubjectCardPart1.vue'
import SubjectCardPart2 from '@/components/api/apiSubject/SubjectCardPart2.vue'
import SubjectCardPart3 from '@/components/api/apiSubject/SubjectCardPart3.vue'
import SubjectCardPart4 from '@/components/api/apiSubject/SubjectCardPart4.vue'

export default {
  name: 'SubjectCard',
  components: { SubjectCardPart1, SubjectCardPart2, SubjectCardPart3, SubjectCardPart4 },
  props: {
    cardId: {
      type: String,
      required: true
    },
    apiAffiliate: {
      type: String,
      default: ''
    },
    fullPage: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      subjectCard: null
    }
  },
  created () {
    this.loadSubjectData()
  },
  methods: {
    loadSubjectData () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.loadingData')
      let url = 'apiSubject/' + this.cardId
      if (this.apiAffiliate != null && this.apiAffiliate.length > 0) {
        url += '/' + this.apiAffiliate
      }
      this.$xapi.get(url)
        .then(r => {
          this.subjectCard = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.info = null
          this.callResult.finished = true
        })
    },
    propagateSubjectListParamsChanged (event) {
      this.$emit('subjectListParamsChanged', event)
    }
  }
}
</script>
