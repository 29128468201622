<template>
  <div>
    <v-data-table
      class="okaml_cursor_pointer"
      dense
      :mobile-breakpoint="0"
      :items="JSON.parse(partData.data).auditTrails"
      :headers="headers"
      :options.sync="opts"
      :sort-by="'created'"
      :footer-props="{ itemsPerPageOptions: [10] }"
      @click:row="noteDetailDialogItemSelect"
    >
      <template #[`item.created`]="{ item }">
        <span style="white-space: nowrap; font-size: 12px; font-weight: 500">{{ $utils.formatDate(item.created + 'Z')
        }}</span>
      </template>
      <template #[`item.typeOfChange`]="{ item }">
        <span style="white-space: nowrap; font-size: 11px;">{{ item.typeOfChange }}</span>
      </template>
      <template #[`item.text`]="{ item }">
        <div
          style="white-space: nowrap; font-size: 12px; max-width: 100px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
        >
          {{
            item.text
          }}
        </div>
      </template>
      <template #[`item.valueChange`]="{ item }">
        <span style="white-space: nowrap; font-size: 11px;">{{ item.valueChange }}</span>
      </template>
      <template v-slot:top>
        <v-btn
          style="top: 10px;"
          color="secondary"
          elevation="0"
          fab
          dark
          x-small
          absolute
          top
          right
          @click="auditTrailNoteDialogOpen()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <basic-dialog
      v-model="auditTrailNoteDialog"
      :title="AuditTraiNoteComputedTitle"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      :disable="callResult.finished"
      @close-dialog="auditTrailNoteDialogOpen()"
      @save-dialog="auditTrailNoteDialogConfirm()"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
        style="position: absolute; z-index: 999; opacity: 0.8; width: 100%"
      />
      <div class="px-4">
        <v-textarea
          ref="refAuditTrailNote"
          v-model="auditTrailNote"
          outlined
          filled
          rows="4"
          background-color="greyf6"
          color="success darken-1"
          :placeholder="'$l.api.subjects.warningChangeNote' | translate"
          prepend-inner-icon="mdi-comment-text"
          :rules="[v => v ? (v.length >= 10 ? true : $t('$l.app.min10charsRequired')) : '']"
          maxlength="256"
          counter
          :error-messages="($v.auditTrailNote.$dirty && !$v.auditTrailNote.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.auditTrailNote.$touch()"
          @blur="$v.auditTrailNote.$touch()"
        />
      </div>
      <template v-slot:actions>
        <v-btn
          v-if="auditTrailObject.created != null"
          prepend-icon="mdi-deletecheck-circle"
          :color="'red'"
          class="ma-0 pa-0 ml-4"
          text
          :loading="false"
          :disabled="auditTrailObject.typeOfChange !== 'CUSTOM NOTE'"
          @click="auditTrailNoteDialogDelete"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          {{ $t('$l.app.delete') }}
        </v-btn>
      </template>
    </basic-dialog>

    <basic-dialog
      v-model="confirmationDeleteNoteDialog"
      :title="$t('$l.app.deleteAuditTrail')"
      @close-dialog="confirmationDeleteNoteDialog = false"
    >
      <app-api-call
        class="ma-0 pa-0 mb-3"
        :call-result="callResult"
        style="position: absolute; z-index: 999; opacity: 0.8; width: 100%"
      />
      <template v-slot:actions>
        <v-btn
          prepend-icon="mdi-deletecheck-circle"
          :color="'red'"
          class="ma-0 pa-0 ml-4"
          text
          :loading="false"
          @click="auditTrailNoteDialogDeleteConfirme"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          {{ $t('$l.app.yes') +', '+ $t('$l.app.delete') }}
        </v-btn>
      </template>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SubjectCardPart2',
  props: {
    partData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      auditTrailNoteDialog: false,
      auditTrailNote: null,
      auditTrailObject: {
        created: null,
        text: null
      },
      noteDetailDialog: false,
      noteDetailDialogItem: null,
      opts: null,
      confirmationDeleteNoteDialog: false
    }
  },
  computed: {
    AuditTraiNoteComputedTitle () {
      if (this.auditTrailObject.created == null) {
        return '$l.api.subjects.auditTrailNoteAdd'
      }

      return '$l.api.subjects.auditTrailNoteEdit'
    },
    headers () {
      return [
        { text: this.$t('Created'), value: 'created', sortable: true, sort: (a, b) => this.getCreatedSort(a, b) },
        { text: this.$t('Type'), value: 'typeOfChange', sortable: true },
        { text: this.$t('Change'), value: 'valueChange', sortable: true },
        { text: this.$t('Text'), value: 'text', sortable: false },
        { text: this.$t('HQ'), value: 'apiHeadQuarter', sortable: true },
        { text: this.$t('Aff'), value: 'apiUserAffiliate', sortable: true },
        { text: this.$t('User'), value: 'apiUserName', sortable: false }
      ]
    }
  },
  validations: {
    auditTrailNote: { required }
  },
  methods: {
    getCreatedSort (a, b) {
      const desc = !this.opts.sortDesc[0]
      return (a.id > b.id) ? (desc ? 1 : -1) : (desc ? -1 : 1)
    },
    auditTrailNoteDialogOpen () {
      this.resetApiCall()

      this.auditTrailNote = null
      this.auditTrailObject = {
        created: null,
        text: null
      }
      this.$v.$reset()
      this.auditTrailNoteDialog = !this.auditTrailNoteDialog
    },
    // CRETE OR UPDATE NOTE
    auditTrailNoteDialogConfirm () {
      this.$v.$touch()
      if (!this.$v.$invalid && this.$refs.refAuditTrailNote.validate()) {
        this.callResult.error = null
        this.callResult.finished = false
        const updateRequest = {}
        updateRequest.subjectId = this.partData.id
        updateRequest.auditTrailNote = this.auditTrailNote
        if (this.auditTrailObject.created) {
          this.$xapi.post('apiSubject/updateAuditTrailNote/' + this.auditTrailObject.created, updateRequest)
            .then(r => {
              this.$emit('subjectListParamsChanged', this.partData.id)
            })
            .catch(e => {
              this.callResult.error = e.response.localizedMessage
            })
            .finally(() => {
              this.callResult.finished = true
            })
        } else {
          this.$xapi.post('apiSubject/addAuditTrailNote', updateRequest)
            .then(r => {
              this.$emit('subjectListParamsChanged', this.partData.id)
            })
            .catch(e => {
              this.callResult.error = e.response.localizedMessage
            })
            .finally(() => {
              this.callResult.finished = true
            })
        }
      }
    },
    resetApiCall () {
      this.callResult.finished = true
      this.callResult.authorized = false
      this.callResult.error = null
      this.callResult.info = null
    },
    // DELETE NOTE
    auditTrailNoteDialogDelete () {
      this.resetApiCall()
      this.confirmationDeleteNoteDialog = true
    },
    auditTrailNoteDialogDeleteConfirme () {
      this.$v.$touch()
      if (!this.$v.$invalid && this.$refs.refAuditTrailNote.validate()) {
        this.callResult.error = null
        this.callResult.finished = false
        const updateRequest = {}
        updateRequest.subjectId = this.partData.id
        updateRequest.auditTrailNote = this.auditTrailNote

        this.$xapi.post('apiSubject/deleteAuditTrailNote/' + this.auditTrailObject.created, updateRequest)
          .then(r => {
            this.$emit('subjectListParamsChanged', this.partData.id)
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.callResult.finished = true
          })
      }
    },
    noteDetailDialogItemSelect (item) {
      // it means Edit Note
      this.auditTrailNoteDialogOpen()
      this.auditTrailNote = item.text
      this.auditTrailObject = item
    }
  }
}
</script>
